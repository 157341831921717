.detail {
  overflow-y: scroll;
  height: calc(100vh - var(--app-footer-height));
  width: 75vw;
  background-color: var(--nwea-ds-v1-gray-50);
}

.detail-header {
  height: 6rem;
  background-color: var(--nwea-ds-v1-gray-100);
  border-bottom: 1px solid var(--nwea-ds-v1-gray-900);
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin: 0 0 3rem;
}

.detail-header>.material-symbols-outlined {
  margin-right: 0.5rem;
}

.detail-columns {
  display: grid;
  grid-template-columns: 2.4rem 20% 17rem 6rem 8rem 30%;
  justify-content: space-between;
  align-items: center;
  margin: 0 3rem;
  font-weight: bold;
  text-transform: uppercase;
  width: calc(100% - 6rem);
}