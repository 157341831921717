.application-footer {
  background-color: var(--nwea-ds-v1-gray-200);
  font-size: 1.8rem;
  height: var(--app-footer-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  color: var(--nwea-ds-v1-gray-900);
}

.link-terms-and-conditions {
  color: var(--nwea-ds-v1-gray-900);
  text-decoration: none;
  border-bottom: 0.2rem solid var(--nwea-ds-v1-gray-900);
}

.link-terms-and-conditions:focus {
  outline: solid var(--nwea-ds-v1-blue-200) 0.5rem;
}