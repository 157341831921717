/*
    Trivial example of a stylesheet specifically focused on formatting properties.
 */

.nwea-ds-v1-template-foo {
    --nwea-ds-template-color: var(--nwea-ds-v1-blue-600);
    border: calc(var(--nwea-ds-template-rem-conversion) * 0.1rem) solid var(--nwea-ds-v1-blue-700);
    border-radius: calc(var(--nwea-ds-template-rem-conversion) * 0.4rem);
}

.nwea-ds-v1-template-bar {
    --nwea-ds-template-color: var(--nwea-ds-v1-green-600);
    border: calc(var(--nwea-ds-template-rem-conversion) * 0.2rem) dashed var(--nwea-ds-v1-green-700);
}

.nwea-ds-v1-template-blah {
    --nwea-ds-template-color: var(--nwea-ds-v1-red-600);
    border: calc(var(--nwea-ds-template-rem-conversion) * 0.3rem) dotted var(--nwea-ds-v1-red-700);
}