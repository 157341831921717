/* Elevation base pseudo states by fill type */
/* color variables that do not change between fills are found in buttonFill.css  */

/* FILLED TYPES and COLORS */
.nwea-ds-v1-button-blue.nwea-ds-v1-button-filled {
  --nwea-ds-v1-button-background-resting: var(--nwea-ds-v1-blue-500);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-blue-400);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-blue-700);
  /* Color of Focus bar */
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-blue-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-blue-200);
}

.nwea-ds-v1-button-blue.nwea-ds-v1-button-outline {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-border-resting: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-blue-100);
  --nwea-ds-v1-button-border-hover: var(--nwea-ds-v1-blue-900);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-blue-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-blue-200);
}

.nwea-ds-v1-button-blue.nwea-ds-v1-button-flat {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-blue-100);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-blue-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-blue-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-blue-200);
}

.nwea-ds-v1-button-purple.nwea-ds-v1-button-filled {
  --nwea-ds-v1-button-background-resting: var(--nwea-ds-v1-purple-500);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-purple-400);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-purple-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-purple-200);
}

.nwea-ds-v1-button-purple.nwea-ds-v1-button-outline {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-border-resting: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-purple-100);
  --nwea-ds-v1-button-border-hover: var(--nwea-ds-v1-purple-900);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-purple-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-purple-200);
}

.nwea-ds-v1-button-purple.nwea-ds-v1-button-flat {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-purple-100);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-purple-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-purple-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-purple-200);
}

.nwea-ds-v1-button-teal-light.nwea-ds-v1-button-filled {
  --nwea-ds-v1-button-color-resting: black;
  --nwea-ds-v1-button-icon-resting: black;
  --nwea-ds-v1-button-background-resting: var(--nwea-ds-v1-teal-500);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-teal-400);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-teal-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-teal-200);
}

.nwea-ds-v1-button-teal-dark.nwea-ds-v1-button-outline {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-border-resting: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-teal-100);
  --nwea-ds-v1-button-border-hover: var(--nwea-ds-v1-teal-900);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-teal-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-teal-200);
}

.nwea-ds-v1-button-teal-dark.nwea-ds-v1-button-flat {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-teal-100);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-teal-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-teal-200);
}

.nwea-ds-v1-button-teal-dark.nwea-ds-v1-button-filled {
  --nwea-ds-v1-button-color-resting: white;
  --nwea-ds-v1-button-icon-resting: white;
  --nwea-ds-v1-button-background-resting: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-teal-600);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-teal-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-teal-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-teal-200);
}

/* no gray filled */

.nwea-ds-v1-button-gray.nwea-ds-v1-button-outline {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-border-resting: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-gray-50);
  --nwea-ds-v1-button-border-hover: var(--nwea-ds-v1-gray-900);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-gray-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-gray-100);
}

.nwea-ds-v1-button-gray.nwea-ds-v1-button-flat {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-gray-50);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-gray-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-gray-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-gray-100);
}

.nwea-ds-v1-button-green.nwea-ds-v1-button-filled {
  --nwea-ds-v1-button-background-resting: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-green-600);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-green-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-green-200);
}

.nwea-ds-v1-button-green.nwea-ds-v1-button-outline {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-border-resting: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-green-100);
  --nwea-ds-v1-button-border-hover: var(--nwea-ds-v1-green-900);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-green-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-green-200);
}

.nwea-ds-v1-button-green.nwea-ds-v1-button-flat {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-green-100);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-green-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-green-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-green-200);
}

.nwea-ds-v1-button-orange.nwea-ds-v1-button-filled {
  --nwea-ds-v1-button-background-resting: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-orange-600);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-orange-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-orange-200);
}

.nwea-ds-v1-button-orange.nwea-ds-v1-button-outline {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-border-resting: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-orange-100);
  --nwea-ds-v1-button-border-hover: var(--nwea-ds-v1-orange-900);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-orange-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-orange-200);
}

.nwea-ds-v1-button-orange.nwea-ds-v1-button-flat {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-orange-100);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-orange-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-orange-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-orange-200);
}


.nwea-ds-v1-button-red.nwea-ds-v1-button-filled {
  --nwea-ds-v1-button-background-resting: var(--nwea-ds-v1-red-500);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-red-400);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-red-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-red-200);
}

.nwea-ds-v1-button-red.nwea-ds-v1-button-outline {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-border-resting: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-red-100);
  --nwea-ds-v1-button-border-hover: var(--nwea-ds-v1-red-900);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-red-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-red-200);
}

.nwea-ds-v1-button-red.nwea-ds-v1-button-flat {
  --nwea-ds-v1-button-color-resting: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-icon-resting: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-background-hover: var(--nwea-ds-v1-red-100);
  --nwea-ds-v1-button-color-focus: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-icon-focus: var(--nwea-ds-v1-red-700);
  --nwea-ds-v1-button-highlight-focus: var(--nwea-ds-v1-red-800);
  --nwea-ds-v1-button-background-active: var(--nwea-ds-v1-red-200);
}

/* no lime */
/* no yellow */
/* no pink */
