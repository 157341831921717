.dashboard {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 2rem;
  background-color: var(--nwea-ds-v1-gray-100);
}

.header-icon {
  margin: 0 1rem;
}
