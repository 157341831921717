.repository-tile {
  position: relative;
  box-shadow: var(--nwea-ds-v1-elevation-03);
  width: 90vw;
  max-width: 100rem;
  margin: 1rem;
  padding: 2rem;
  border-radius: 0.5rem;
  text-decoration: none;
  color: var(--nwea-ds-v1-gray-900)
}

.repository-tile:hover {
  box-shadow: var(--nwea-ds-v1-elevation-02);
}

.repository-tile__content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.repository-tile__pipes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 20rem;
} 

.repository-tile__pipe-label {
  font-weight: normal;
  margin: 0;
}

.repository-tile__indicator {
  color: var(--nwea-ds-v1-gray-500);
  font-size: 3rem;
  text-align: right;
  height: 100%;
  display: flex;
  align-items: center;
}

.repository-tile__indicator-success {
  color: var(--nwea-ds-v1-green-600);
}

.repository-tile__indicator-failure {
  color: var(--nwea-ds-v1-red-500);
}

.repository-tile__item {
  position: relative;
  flex-basis: 25%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.repository-tile__item > .repository-tile__tooltip {
  visibility: hidden;
}

.repository-tile__item:hover > .repository-tile__tooltip {
  visibility: visible;
}

.repository-tile__tooltip {
  position: absolute;
  top: -2rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--nwea-ds-v1-gray-800);
  background-color: var(--nwea-ds-v1-gray-50);
  white-space: nowrap;
}