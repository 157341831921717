@keyframes in-progress-spinner {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.execution {
  margin: 1rem 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--nwea-ds-v1-gray-900);
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 2.4rem 20% 17rem 6rem 8rem 30%;
  justify-content: space-between;
  align-items: center;
}

.execution-succeeded {
  background-color: var(--color-succeeded);
}

.execution-failed {
  background-color: var(--color-failed);
}

.execution-in-progress {
  background-color: var(--color-in-progress);
}

.execution-waiting {
  background-color: var(--color-in-progress);
}

.execution-na {
  background-color: var(--color-na);
}

.execution-status-succeeded {
  color: var(--color-succeeded-dark);
}

.execution-status-failed {
  color: var(--color-failed-dark);
}

.execution-status-in-progress {
  color: var(--color-in-progress-dark);
  animation: 1s in-progress-spinner infinite linear;
}

.execution-status-waiting {
  color: var(--color-in-progress-dark);
}

.execution-status-na {
  color: var(--color-na-dark);
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}