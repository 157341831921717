.standard-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.standard-layout-content {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--nwea-ds-v1-gray-100);
  height: 100%;
}