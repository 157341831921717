/* NWEA Design System - Elevation */

:root, :host {
    --nwea-ds-v1-elevation-rem-conversion: calc(10 / var(--nwea-ds-v1-rem-basis, 16));
    --nwea-ds-v1-elevation-1px: calc(0.1rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-2px: calc(0.2rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-3px: calc(0.3rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-4px: calc(0.4rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-5px: calc(0.5rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-6px: calc(0.6rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-7px: calc(0.7rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-8px: calc(0.8rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-9px: calc(0.9rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-10px: calc(1.0rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-11px: calc(1.1rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-12px: calc(1.2rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-14px: calc(1.4rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-15px: calc(1.5rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-16px: calc(1.6rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-17px: calc(1.7rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-18px: calc(1.8rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-22px: calc(2.2rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-24px: calc(2.4rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-30px: calc(3.0rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-38px: calc(3.8rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-46px: calc(4.6rem * var(--nwea-ds-v1-elevation-rem-conversion));
    --nwea-ds-v1-elevation-animation: 0.25s;
    --nwea-ds-v1-elevation-0: 0 0 0 0 rgba(0, 0, 0, 0);
    --nwea-ds-v1-elevation-01: 0 var(--nwea-ds-v1-elevation-1px) var(--nwea-ds-v1-elevation-1px) 0 rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-2px) var(--nwea-ds-v1-elevation-1px) calc(-1 * var(--nwea-ds-v1-elevation-1px)) rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-1px) var(--nwea-ds-v1-elevation-3px) 0 rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-02: 0 var(--nwea-ds-v1-elevation-2px) var(--nwea-ds-v1-elevation-2px) 0 rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-3px) var(--nwea-ds-v1-elevation-1px) calc(-1 * var(--nwea-ds-v1-elevation-2px)) rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-1px) var(--nwea-ds-v1-elevation-5px) 0 rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-03: 0 var(--nwea-ds-v1-elevation-3px) var(--nwea-ds-v1-elevation-4px) 0 rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-3px) var(--nwea-ds-v1-elevation-3px) calc(-1 * var(--nwea-ds-v1-elevation-2px)) rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-1px) var(--nwea-ds-v1-elevation-8px) 0 rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-04: 0 var(--nwea-ds-v1-elevation-4px) var(--nwea-ds-v1-elevation-5px) 0 rgba(0, 0, 0, 0.14), 
                             0 var(--nwea-ds-v1-elevation-1px) var(--nwea-ds-v1-elevation-10px) 0 rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-2px) var(--nwea-ds-v1-elevation-4px) calc(-1 * var(--nwea-ds-v1-elevation-1px)) rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-06: 0 var(--nwea-ds-v1-elevation-6px) var(--nwea-ds-v1-elevation-10px) 0 rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-1px) var(--nwea-ds-v1-elevation-18px) 0 rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-3px) var(--nwea-ds-v1-elevation-5px) calc(-1 * var(--nwea-ds-v1-elevation-1px)) rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-08: 0 var(--nwea-ds-v1-elevation-8px) var(--nwea-ds-v1-elevation-10px) var(--nwea-ds-v1-elevation-1px) rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-3px) var(--nwea-ds-v1-elevation-14px) var(--nwea-ds-v1-elevation-2px) rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-5px) var(--nwea-ds-v1-elevation-5px) calc(-1 * var(--nwea-ds-v1-elevation-3px)) rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-09: 0 var(--nwea-ds-v1-elevation-9px) var(--nwea-ds-v1-elevation-12px) var(--nwea-ds-v1-elevation-1px) rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-3px) var(--nwea-ds-v1-elevation-16px) var(--nwea-ds-v1-elevation-2px) rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-5px) var(--nwea-ds-v1-elevation-6px) calc(-1 * var(--nwea-ds-v1-elevation-3px)) rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-12: 0 var(--nwea-ds-v1-elevation-12px) var(--nwea-ds-v1-elevation-17px) var(--nwea-ds-v1-elevation-2px) rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-5px) var(--nwea-ds-v1-elevation-22px) var(--nwea-ds-v1-elevation-4px) rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-7px) var(--nwea-ds-v1-elevation-8px) calc(-1 * var(--nwea-ds-v1-elevation-4px)) rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-16: 0 var(--nwea-ds-v1-elevation-16px) var(--nwea-ds-v1-elevation-24px) var(--nwea-ds-v1-elevation-2px) rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-6px) var(--nwea-ds-v1-elevation-30px) var(--nwea-ds-v1-elevation-5px) rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-8px) var(--nwea-ds-v1-elevation-10px) calc(-1 * var(--nwea-ds-v1-elevation-5px)) rgba(0, 0, 0, 0.2);
    --nwea-ds-v1-elevation-24: 0 var(--nwea-ds-v1-elevation-24px) var(--nwea-ds-v1-elevation-38px) var(--nwea-ds-v1-elevation-3px) rgba(0, 0, 0, 0.14),
                             0 var(--nwea-ds-v1-elevation-9px) var(--nwea-ds-v1-elevation-46px) var(--nwea-ds-v1-elevation-8px) rgba(0, 0, 0, 0.12),
                             0 var(--nwea-ds-v1-elevation-11px) var(--nwea-ds-v1-elevation-15px) calc(-1 * var(--nwea-ds-v1-elevation-7px)) rgba(0, 0, 0, 0.2);
}

.nwea-ds-v1-elevation-animation {
    transition: var(--nwea-ds-v1-elevation-animation);
}

.nwea-ds-v1-elevation-level-0 {
    box-shadow: var(--nwea-ds-v1-elevation-0);
}

.nwea-ds-v1-elevation-level-01 {
    box-shadow: var(--nwea-ds-v1-elevation-01);
}

.nwea-ds-v1-elevation-level-02 {
    box-shadow: var(--nwea-ds-v1-elevation-02);
}

.nwea-ds-v1-elevation-level-03 {
    box-shadow: var(--nwea-ds-v1-elevation-03);
}

.nwea-ds-v1-elevation-level-04 {
    box-shadow: var(--nwea-ds-v1-elevation-04);
}

.nwea-ds-v1-elevation-level-06 {
    box-shadow: var(--nwea-ds-v1-elevation-06);
}

.nwea-ds-v1-elevation-level-08 {
    box-shadow: var(--nwea-ds-v1-elevation-08);
}

.nwea-ds-v1-elevation-level-09 {
    box-shadow: var(--nwea-ds-v1-elevation-09);
}

.nwea-ds-v1-elevation-level-12 {
    box-shadow: var(--nwea-ds-v1-elevation-12);
}

.nwea-ds-v1-elevation-level-16 {
    box-shadow: var(--nwea-ds-v1-elevation-16);
}

.nwea-ds-v1-elevation-level-24 {
    box-shadow: var(--nwea-ds-v1-elevation-24);
}
