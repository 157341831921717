/* SIZES */
.nwea-ds-v1-button-large {
  --nwea-ds-v1-button-height: calc(var(--nwea-ds-v1-button-rem-conversion) * 4.8rem);
  --nwea-ds-v1-button-icon-only-margin: calc(var(--nwea-ds-v1-button-rem-conversion) * -0.4rem);
}

.nwea-ds-v1-button-medium {
  --nwea-ds-v1-button-height: calc(var(--nwea-ds-v1-button-rem-conversion) * 4rem);
  --nwea-ds-v1-button-icon-only-margin: calc(var(--nwea-ds-v1-button-rem-conversion) * -0.8rem);
}

.nwea-ds-v1-button-small {
  --nwea-ds-v1-button-height: calc(var(--nwea-ds-v1-button-rem-conversion) * 3.2rem);
  --nwea-ds-v1-button-icon-only-margin: calc(var(--nwea-ds-v1-button-rem-conversion) * -1.2rem);
}
