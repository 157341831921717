:root, :host {
    --nwea-ds-v1-button-rem-conversion: calc(10 / var(--nwea-ds-v1-rem-basis, 16));
}

/* Duplication of justify-content and vertical-align are intentional. justify-content for Edge to align of focus indicator in outline iconOnly button correctly. vertical-align is responsible for alignment of button element inline positioning. */

/* BASE BUTTON */
.nwea-ds-v1-button-base {
  /* --nwea-ds-v1-button-icon-fill: var(--nwea-ds-v1-button-icon-resting); */
  -webkit-font-smoothing: antialiased;
  align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--nwea-ds-v1-button-background-resting);
  border-radius: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem);
  border: 0;
  box-shadow: var(--nwea-ds-v1-button-elevation-resting);
  color: var(--nwea-ds-v1-button-color-resting);
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: calc(var(--nwea-ds-v1-button-rem-conversion) * 1.4rem);
  font-weight: 600;
  height: var(--nwea-ds-v1-button-height);
  justify-content: center;
  letter-spacing: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.072rem);
  line-height: calc(var(--nwea-ds-v1-button-rem-conversion) * 1.6rem);
  margin: 0;
  min-width: calc(var(--nwea-ds-v1-button-rem-conversion) * 3.2rem);
  outline: none;
  padding: var(--nwea-ds-v1-button-padding);
  position: relative;
  transition-property: background-color, box-shadow, color, z-index;
  transition-duration: 0.25s;
  transition-timing-function: ease, ease, ease, step-end;
  vertical-align: middle;
  /* TODO: need to revisit z-index for buttons, tooltips and focus. */
  z-index: 0;
}

/* Focus indicator color bar that sits on top of the main button bg so that colors in bg can be preserved */
.nwea-ds-v1-button-base::after {
  background-color: var(--nwea-ds-v1-button-highlight-resting);
  border-radius: 0 0 calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem) calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem);
  border: 0;
  bottom: 0;
  content: ' ';
  display: block;
  height: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem);
  outline: none;
  pointer-events: none;
  position: absolute;
  transition: 0.15s;
  width: 0;
  z-index: -1;
}

/* HOVER */
/* nwea-ds-test-hover-state is a class used for Visual Regression testing. */
:host(.nwea-ds-test-hover-state) .nwea-ds-v1-button-base,
.nwea-ds-v1-button-base:hover {
  background-color: var(--nwea-ds-v1-button-background-hover);
  box-shadow: var(--nwea-ds-v1-button-elevation-hover);
  color: var(--nwea-ds-v1-button-color-hover);
  z-index: 15;
}

/* FOCUS */
.nwea-ds-v1-button-base:not(.nwea-ds-v1-button-focus-override):focus {
  background-color: var(--nwea-ds-v1-button-background-focus);
  box-shadow: var(--nwea-ds-v1-button-elevation-focus);
  color: var(--nwea-ds-v1-button-color-focus);
  z-index: 15;
}

/* focus-visible is useful to provide a different focus indicator based on the user’s input modality. */
/* https://css-tricks.com/the-focus-visible-trick */
/* MDN specification, https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
.nwea-ds-v1-button-base.nwea-ds-v1-button-focus-override:focus-visible {
  background-color: var(--nwea-ds-v1-button-background-focus);
  box-shadow: var(--nwea-ds-v1-button-elevation-focus);
  color: var(--nwea-ds-v1-button-color-focus);
  z-index: 15;
}

.nwea-ds-v1-button-base:not(.nwea-ds-v1-button-focus-override):focus::after {
  background-color: var(--nwea-ds-v1-button-highlight-focus);
  width: 100%;
}

.nwea-ds-v1-button-base.nwea-ds-v1-button-focus-override:focus-visible::after {
  background-color: var(--nwea-ds-v1-button-highlight-focus);
  width: 100%;
}

/* ACTIVE */
/* nwea-ds-test-active-state is a class used for Visual Regression testing. */
:host(.nwea-ds-test-active-state) .nwea-ds-v1-button-base:not([disabled]),
.nwea-ds-v1-button-base:not([disabled]):active {
  -webkit-tap-highlight-color: var(--nwea-ds-v1-button-background-active);
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-name: nwea-ds-v1-button-push;
  animation-timing-function: ease-in-out;
  background-color: var(--nwea-ds-v1-button-background-active);
  box-shadow: var(--nwea-ds-v1-button-elevation-active);
  color: var(--nwea-ds-v1-button-color-active);
}

/* Keyframe animation included slowing the animation on active so that it is perceptible. */
@keyframes nwea-ds-v1-button-push {
  40% {
    background-color: var(--nwea-ds-v1-button-background-active);
    box-shadow: var(--nwea-ds-v1-button-elevation-resting);
  }
}

/* DISABLED BUTTON CLASSES */
/* nwea-ds-test-hover-state is a class used for Visual Regression testing. */
:host(.nwea-ds-test-hover-state) .nwea-ds-v1-button-base:disabled,
.nwea-ds-v1-button-base:disabled,
.nwea-ds-v1-button-base:disabled:hover {
  background-color: var(--nwea-ds-v1-gray-150);
  box-shadow: none;
  color: var(--nwea-ds-v1-gray-550);
  cursor: not-allowed;
}

/* SPECIFIC BLENDED STATES  */
.nwea-ds-v1-button-base:not([disabled]):active::after,
.nwea-ds-v1-button-base:focus:active::after {
  background-color: var(--nwea-ds-v1-button-highlight-default);
  transition: 0.15s;
  width: 0;
}
