.page-header {
  box-shadow: var(--nwea-ds-v1-elevation-01);
  color: var(--nwea-ds-v1-gray-800);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--nwea-ds-v1-gray-300);
  box-shadow: var(--nwea-ds-v1-elevation-02);
  padding: 1rem 3rem;
  min-height: 2.4rem;
  align-items: center;
}

.page-header a {
  color: var(--nwea-ds-v1-gray-50);
}