:root, :host {
  --nwea-ds-v1-button-tooltip-offset-visible: calc(100% + calc(var(--nwea-ds-v1-button-rem-conversion) * 0.8rem));
}

/* TOOLTIP base */
.nwea-ds-v1-button-tooltip-base {
  /* The offset variables account for verbose tooltip text. A11y focus indicators can look mismatched because the tooltip is not visible and the A11y indicator wraps it.  */
  --nwea-ds-v1-button-tooltip-offset: 50%; /* default to position within the button bounds */
  position: relative;
}

.nwea-ds-v1-button-tooltip-base[tooltip-text]::before {
  background-color: var(--nwea-ds-v1-gray-800);
  border-radius: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem);
  bottom: var(--nwea-ds-v1-button-tooltip-bottom);
  color: white;
  content: attr(tooltip-text);
  font-size: calc(var(--nwea-ds-v1-button-rem-conversion) * 1.4rem);
  left: var(--nwea-ds-v1-button-tooltip-left);
  max-height: 0;
  max-width: 0;
  opacity: 0;
  padding: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem) calc(var(--nwea-ds-v1-button-rem-conversion) * 0.8rem);
  pointer-events: none;
  position: absolute;
  right: var(--nwea-ds-v1-button-tooltip-right);
  text-align: center;
  text-transform: none;
  top: var(--nwea-ds-v1-button-tooltip-top);
  transition: all 0.25s step-end, opacity 0.25s linear;
  width: max-content;
  transform: translateY(var(--nwea-ds-v1-button-tooltip-vertical-adjustment, 0));
}

/* nwea-ds-test-hover-state is a class used for Visual Regression testing. */
:host(.nwea-ds-test-hover-state) .nwea-ds-v1-button-tooltip-base[tooltip-text],
.nwea-ds-v1-button-tooltip-base[tooltip-text]:hover,
.nwea-ds-v1-button-tooltip-base[tooltip-text]:focus {
  --nwea-ds-v1-button-tooltip-offset: var(--nwea-ds-v1-button-tooltip-offset-visible);
}

:host(.nwea-ds-test-hover-state) .nwea-ds-v1-button-tooltip-base[tooltip-text]::before,
.nwea-ds-v1-button-tooltip-base[tooltip-text]:hover::before,
.nwea-ds-v1-button-tooltip-base[tooltip-text]:focus::before {
  max-width: 100vw;
  max-height: 100vh;
  opacity: 1;
  transition: all 0.25s step-start, opacity 0.25s linear;
}
