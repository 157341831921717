.nwea-ds-v1-button-filled {
  /* Elevations */
  --nwea-ds-v1-button-elevation-active: var(--nwea-ds-v1-elevation-01);
  --nwea-ds-v1-button-elevation-focus: var(--nwea-ds-v1-elevation-04);
  --nwea-ds-v1-button-elevation-hover: var(--nwea-ds-v1-elevation-02);
  --nwea-ds-v1-button-elevation-resting: var(--nwea-ds-v1-elevation-00);
  /* Common Fill and Color Definitions */
  --nwea-ds-v1-button-color-resting: white;
  --nwea-ds-v1-button-icon-resting: white;
  --nwea-ds-v1-button-color-hover: white;
  --nwea-ds-v1-button-icon-hover: white;
  --nwea-ds-v1-button-background-focus: white;
  --nwea-ds-v1-button-color-active: black;
  --nwea-ds-v1-button-icon-active: black;
  --nwea-ds-v1-button-padding: 0 calc(var(--nwea-ds-v1-button-rem-conversion) * 1.6rem);
}

/* Alternate Fill and Color Definition */
.nwea-ds-v1-button-teal-light.nwea-ds-v1-button-filled {
  --nwea-ds-v1-button-color-hover: black;
  --nwea-ds-v1-button-icon-hover: black;
  --nwea-ds-v1-button-background-focus: white;
  --nwea-ds-v1-button-color-active: black;
  --nwea-ds-v1-button-icon-active: black;
}

.nwea-ds-v1-button-outline {
  /* Elevations */
  --nwea-ds-v1-button-elevation-active: var(--nwea-ds-v1-elevation-01);
  --nwea-ds-v1-button-elevation-focus: var(--nwea-ds-v1-elevation-04);
  --nwea-ds-v1-button-elevation-hover: var(--nwea-ds-v1-elevation-00);
  --nwea-ds-v1-button-elevation-resting: var(--nwea-ds-v1-elevation-00);
  /* Common Fill and Color Definitions */
  --nwea-ds-v1-button-background-resting: white;
  --nwea-ds-v1-button-highlight-resting: white;
  --nwea-ds-v1-button-color-hover: black;
  --nwea-ds-v1-button-icon-hover: black;
  --nwea-ds-v1-button-background-focus: white;
  --nwea-ds-v1-button-border-focus: white;
  --nwea-ds-v1-button-color-active: black;
  --nwea-ds-v1-button-icon-active: black;
  --nwea-ds-v1-button-border-active: white;
  --nwea-ds-v1-button-padding: 0 calc(var(--nwea-ds-v1-button-rem-conversion) * 1.5rem);
}

/* no lime */
/* no yellow */
/* no pink */

/* TODO: This is for the focus override spacing for boarder. */
/* .outline > * {
  margin-top: calc(var(--nwea-ds-v1-button-rem-conversion) * -0.1rem);
} */

.nwea-ds-v1-button-outline:not([disabled]) {
  border: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.1rem) solid var(--nwea-ds-v1-button-border-resting);
  box-sizing: border-box;
}

.nwea-ds-v1-button-outline:not(.nwea-ds-v1-button-focus-override):focus {
  border: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.1rem) solid var(--nwea-ds-v1-button-border-focus);
  box-sizing: border-box;
}

/* focus-visible is useful to provide a different focus indicator based on the user’s input modality. */
/* https://css-tricks.com/the-focus-visible-trick */
/* MDN specification, https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
.nwea-ds-v1-button-outline.nwea-ds-v1-button-focus-override:focus-visible {
  border: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.1rem) solid var(--nwea-ds-v1-button-border-focus);
  box-sizing: border-box;
}

.nwea-ds-v1-button-outline:not(.nwea-ds-v1-button-focus-override):focus::after {
  background-color: var(--nwea-ds-v1-button-highlight-focus);
  bottom: calc(var(--nwea-ds-v1-button-rem-conversion) * -0.2rem);
  width: calc(100% + calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem));
  box-sizing: border-box;
}

.nwea-ds-v1-button-outline.nwea-ds-v1-button-focus-override:focus-visible::after {
  background-color: var(--nwea-ds-v1-button-highlight-focus);
  bottom: calc(var(--nwea-ds-v1-button-rem-conversion) * -0.2rem);
  width: calc(100% + calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem));
  box-sizing: border-box;
}

.nwea-ds-v1-button-flat {
  /* Elevations */
  --nwea-ds-v1-button-elevation-active: var(--nwea-ds-v1-elevation-01);
  --nwea-ds-v1-button-elevation-focus: var(--nwea-ds-v1-elevation-04);
  --nwea-ds-v1-button-elevation-hover: var(--nwea-ds-v1-elevation-00);
  --nwea-ds-v1-button-elevation-resting: var(--nwea-ds-v1-elevation-00);
  /* Common Fill and Color Definitions */
  --nwea-ds-v1-button-background-resting: transparent;
  --nwea-ds-v1-button-highlight-resting: white;
  --nwea-ds-v1-button-color-hover: black;
  --nwea-ds-v1-button-icon-hover: black;
  --nwea-ds-v1-button-background-focus: white;
  --nwea-ds-v1-button-color-active: black;
  --nwea-ds-v1-button-icon-active: black;
  --nwea-ds-v1-button-padding: 0 calc(var(--nwea-ds-v1-button-rem-conversion) * 1.6rem);
}
