:host {
    /*
        Use REM measurements instead of PX for all sizing.
        A conversion variable is needed to convert 10px-based measurements to the root measurement for the document.
        It's good to have a conversion variable for measurements that are specific to your component instead of a global one.
        That way, if no other components or styling from the DS are used, you still have a valid conversion to use.
     */
    --nwea-ds-template-rem-conversion: calc(10 / var(--nwea-ds-v1-rem-basis, 16));
    --nwea-ds-template-color: var(--nwea-ds-v1-blue-700);
    --nwea-ds-template-font-size: calc(var(--nwea-ds-template-rem-conversion) * 0.8rem);
    --nwea-ds-template-margin: 0 calc(var(--nwea-ds-template-rem-conversion) * 0.8rem);
}

/*
    This is a good place to put core styling for your component.
    While styling that is specific to certain attribute options can be handled in this file,
        it can be helpful to segregate styling into different CSS files in order to reduce complexity and length of the files.
    One method of helping readability across multiple CSS files is to establish most/all of the properties in this file,
        but reference component-specific variables, and then set those variables in the other purpose-specific CSS files.
 */

.nwea-ds-v1-template-example-content {
    display: inline-block;
    font-size: var(--nwea-ds-template-font-size);
    margin: var(--nwea-ds-template-margin);
    color: var(--nwea-ds-template-color);
}

.nwea-ds-v1-template-example-child {
    color: var(--nwea-ds-template-color);
}