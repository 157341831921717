/* NWEA Design System - Typography */

:root, :host {
    --nwea-ds-v1-typography-rem-percentage: calc(var(--nwea-ds-v1-rem-basis, 16) / 16 * 100);
    --nwea-ds-v1-typography-rem-conversion: calc(10 / var(--nwea-ds-v1-rem-basis, 16));
    --nwea-ds-v1-typography-font-family: 'Open Sans', sans-serif;
}

.nwea-ds-v1-typography-html-default {
    font-size: calc(var(--nwea-ds-v1-typography-rem-percentage) * 1%);
}

.nwea-ds-v1-typography-body-default {
    /* this needs to be browser specific because font-smooth is inconsistent in support */
    -webkit-font-smoothing: antialiased;
    color: var(--nwea-ds-v1-gray-900);
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 1.4rem);
    font-weight: 400;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.025rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.4rem);
    margin: 0;
}

.nwea-ds-v1-typography-body-copy-large {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2rem);
    font-weight: 300;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.052rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 3.2rem);
    margin: 0 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * 4.8rem) 0;
}

.nwea-ds-v1-typography-body-copy-medium {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 1.6rem);
    font-weight: 400;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.015rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.4rem);
    margin: 0 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * 4rem) 0;
}

.nwea-ds-v1-typography-body-copy-default {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 1.4rem);
    font-weight: 400;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.026rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.4rem);
    margin: 0 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * 4rem) 0;
}

.nwea-ds-v1-typography-heading-1 {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 4.8rem);
    font-weight: 400;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * -0.1rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 7.2rem);
    margin: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.8rem) 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.4rem) calc(var(--nwea-ds-v1-typography-rem-conversion) * -0.4rem);
}

.nwea-ds-v1-typography-heading-2 {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 4rem);
    font-weight: 400;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.05rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 5.6rem);
    margin: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.8rem) 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.4rem) calc(var(--nwea-ds-v1-typography-rem-conversion) * -0.36rem);
}

.nwea-ds-v1-typography-heading-3 {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 3.2rem);
    font-weight: 600;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.1rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 4.8rem);
    margin: 0 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * 2rem)  calc(var(--nwea-ds-v1-typography-rem-conversion) * -0.24rem);
}

.nwea-ds-v1-typography-heading-4 {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.8rem);
    font-weight: 600;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.1rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 4.8rem);
    margin: 0 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * 2rem)  calc(var(--nwea-ds-v1-typography-rem-conversion) * -0.26rem);
}

.nwea-ds-v1-typography-heading-5 {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2rem);
    font-weight: 700;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.1rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.8rem);
    margin: 0 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * 1.6rem)  calc(var(--nwea-ds-v1-typography-rem-conversion) * -0.18rem);
}

.nwea-ds-v1-typography-heading-6 {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 1.2rem);
    font-weight: 700;
    letter-spacing: calc(var(--nwea-ds-v1-typography-rem-conversion) * 0.15rem);
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 3.2rem);
    margin: 0 0 0 calc(var(--nwea-ds-v1-typography-rem-conversion) * -0.14rem);
    text-transform: uppercase;
}

.nwea-ds-v1-typography-subtitle {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 1.6rem);
    font-weight: 600;
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.4rem);
}

.nwea-ds-v1-typography-label {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 1.4rem);
    font-weight: 600;
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.4rem);
}

.nwea-ds-v1-typography-caption {
    font-family: var(--nwea-ds-v1-typography-font-family);
    font-size: calc(var(--nwea-ds-v1-typography-rem-conversion) * 1.2rem);
    font-weight: 400;
    line-height: calc(var(--nwea-ds-v1-typography-rem-conversion) * 2.0rem);
}
