@keyframes slideOpen {
  0% {
    right: -110vw;
  }

  100% {
    right: 0;
  }
}

@keyframes obscure {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

.page-slider {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-slider__fader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--nwea-ds-v1-gray-900);
  animation: var(--standard-transition-duration) obscure forwards;
}

.page-slider__content-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  animation: var(--standard-transition-duration) slideOpen forwards;
}