/* NWEA Design System - Toggle */

:host {
    --nwea-ds-toggle-track-height: calc(var(--nwea-ds-switch-rem-conversion) * 2.4rem);
    --nwea-ds-toggle-track-width: calc(var(--nwea-ds-switch-rem-conversion) * 4.8rem);
    --nwea-ds-toggle-margin: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
    --nwea-ds-toggle-border: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
}

.nwea-ds-v1-toggle-control {
    /* Extremely large border radii cause an overlap situation,
           resulting in a recalculation to the smallest radius that doesn't cause overlap,
           and applying the reduction proportionally across all radii.
       https://drafts.csswg.org/css-backgrounds/#corner-overlap
       Using this method of rounding the edges instead of specifying a pre-determined amount
           in order to make the measurement relative to the size of the toggle.
    */
    --nwea-ds-switch-focus-ring-border-radius: 100vh;
    --nwea-ds-switch-height: calc(var(--nwea-ds-toggle-track-height) + (var(--nwea-ds-toggle-margin) * 2) + (var(--nwea-ds-toggle-border) * 2));
    --nwea-ds-switch-width: calc(var(--nwea-ds-toggle-track-width) + (var(--nwea-ds-toggle-margin) * 2) + (var(--nwea-ds-toggle-border) * 2));
    --nwea-ds-switch-background-color: var(--nwea-ds-v1-gray-500); /* track color (initial) */
    --nwea-ds-switch-outline-border-color: transparent; /* This control has a track, not an outline. */
    --nwea-ds-switch-outline-border-radius: 100vh;
    --nwea-ds-switch-outline-border-width: 0;
    --nwea-ds-switch-outline-width: calc(100% - (var(--nwea-ds-toggle-margin) * 2));
    --nwea-ds-switch-outline-height: calc(100% - (var(--nwea-ds-toggle-margin) * 2));
    --nwea-ds-switch-outline-margin-top: var(--nwea-ds-toggle-margin);
    --nwea-ds-switch-outline-margin-offset: var(--nwea-ds-toggle-margin);

    order: -3;
}

.nwea-ds-v1-toggle-control::before {
    transition: 0.2s;
}

.nwea-ds-v1-toggle-control:checked, .nwea-ds-v1-toggle-control:disabled {
    --nwea-ds-switch-background-color: var(--nwea-ds-switch-color);
}

.nwea-ds-v1-toggle-control:checked ~ .nwea-ds-v1-toggle-icon {
    fill: var(--nwea-ds-switch-color);
    margin: calc(var(--nwea-ds-switch-rem-conversion) * 0.5rem)
            calc(var(--nwea-ds-switch-rem-conversion) * 0.3rem)
            0
            calc(var(--nwea-ds-switch-rem-conversion) * -2.6rem);
}

.nwea-ds-v1-toggle-control:disabled ~ .nwea-ds-v1-toggle-icon {
    --nwea-ds-switch-color: var(--nwea-ds-v1-gray-450);
    background-color: var(--nwea-ds-v1-gray-150);
    box-shadow: var(--nwea-ds-v1-elevation-0);
}

.nwea-ds-v1-switch-control-is-centered .nwea-ds-v1-toggle-control {
    margin: calc(var(--nwea-ds-switch-rem-conversion) * 0.1rem)
            0
            calc(var(--nwea-ds-switch-rem-conversion) * -0.3rem);
}

.nwea-ds-v1-toggle-icon {
    background-color: white;
    border-radius: 50%;
    box-shadow: var(--nwea-ds-v1-elevation-01);
    box-sizing: border-box;
    fill: transparent;
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(var(--nwea-ds-switch-rem-conversion) * 2rem);
    margin: calc(var(--nwea-ds-switch-rem-conversion) * 0.5rem)
            calc(var(--nwea-ds-switch-rem-conversion) * 2.7rem)
            0
            calc(var(--nwea-ds-switch-rem-conversion) * -5rem);
    width: calc(var(--nwea-ds-switch-rem-conversion) * 2rem);
    order: -1;
    padding: 0 calc(var(--nwea-ds-switch-rem-conversion) * 0.1rem) calc(var(--nwea-ds-switch-rem-conversion) * 0.1rem);
    position: relative;
    transition: 0.2s;
}

.nwea-ds-v1-switch-label-on-left .nwea-ds-v1-toggle-icon {
    margin: calc(var(--nwea-ds-switch-rem-conversion) * 0.5rem)
            calc(var(--nwea-ds-switch-rem-conversion) * -2.6rem)
            0
            calc(var(--nwea-ds-switch-rem-conversion) * 0.3rem);
}

.nwea-ds-v1-switch-label-on-left .nwea-ds-v1-toggle-control:checked ~ .nwea-ds-v1-toggle-icon {
    margin: calc(var(--nwea-ds-switch-rem-conversion) * 0.5rem)
    calc(var(--nwea-ds-switch-rem-conversion) * -5rem)
    0
    calc(var(--nwea-ds-switch-rem-conversion) * 2.7rem);
}

.nwea-ds-v1-switch-control-is-centered .nwea-ds-v1-toggle-icon {
    margin-top: calc(var(--nwea-ds-switch-rem-conversion) * 0.4rem);
}

.nwea-ds-v1-switch-control-is-centered .nwea-ds-v1-toggle-control:checked ~ .nwea-ds-v1-toggle-icon {
    margin-top: calc(var(--nwea-ds-switch-rem-conversion) * 0.4rem);
}

.nwea-ds-v1-switch-label-hidden.nwea-ds-v1-toggle-container {
    --nwea-ds-switch-width: calc(var(--nwea-ds-toggle-track-width) + (var(--nwea-ds-toggle-margin) * 2) + (var(--nwea-ds-toggle-border) * 2));
}

.nwea-ds-v1-toggle-label {
    --nwea-ds-switch-label-space-side: calc(var(--nwea-ds-switch-rem-conversion) * 0.6rem);
    --nwea-ds-switch-label-space-top: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
}
