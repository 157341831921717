/* NWEA Design System - Hello World */

.nwea-ds-v1-helloWorld-gray { color: var(--nwea-ds-v1-gray-900); }
.nwea-ds-v1-helloWorld-yellow { color: var(--nwea-ds-v1-yellow-900); }
.nwea-ds-v1-helloWorld-blue { color: var(--nwea-ds-v1-blue-900); }
.nwea-ds-v1-helloWorld-teal { color: var(--nwea-ds-v1-teal-900); }
.nwea-ds-v1-helloWorld-green { color: var(--nwea-ds-v1-green-900); }
.nwea-ds-v1-helloWorld-lime { color: var(--nwea-ds-v1-lime-900); }
.nwea-ds-v1-helloWorld-purple { color: var(--nwea-ds-v1-purple-900); }
.nwea-ds-v1-helloWorld-pink { color: var(--nwea-ds-v1-pink-900); }
.nwea-ds-v1-helloWorld-red { color: var(--nwea-ds-v1-red-900); }
.nwea-ds-v1-helloWorld-orange { color: var(--nwea-ds-v1-orange-900); }

.nwea-ds-v1-helloWorld-content { display: block; }
