:host {
  --nwea-ds-v1-button-icon-margin: calc(var(--nwea-ds-v1-button-rem-conversion) * -0.4rem);
}

::slotted(svg) {
  /* When an svg is used in a button, it blocks the click of the button. So, you have to tell the svg to allow the click to pass through by using a pointer event.
  Article: https://css-tricks.com/slightly-careful-sub-elements-clickable-things/
  */

  pointer-events: none;
  width: calc(var(--nwea-ds-v1-button-rem-conversion) * 2.4rem);
  height: calc(var(--nwea-ds-v1-button-rem-conversion) * 2.4rem);
  fill: currentColor;
  margin-left: var(--nwea-ds-v1-button-icon-margin-left, var(--nwea-ds-v1-button-icon-margin));
  margin-right: var(--nwea-ds-v1-button-icon-margin-right, var(--nwea-ds-v1-button-icon-margin));
}

.nwea-ds-v1-button-icon-on-left {
  --nwea-ds-v1-button-icon-margin-right: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem);
}

.nwea-ds-v1-button-icon-on-right {
  --nwea-ds-v1-button-icon-margin-left: calc(var(--nwea-ds-v1-button-rem-conversion) * 0.4rem);
}

.nwea-ds-v1-button-icon-only {
  --nwea-ds-v1-button-icon-margin-left: var(--nwea-ds-v1-button-icon-only-margin);
  --nwea-ds-v1-button-icon-margin-right: var(--nwea-ds-v1-button-icon-only-margin);
}

.nwea-ds-v1-button-icon-only.nwea-ds-v1-button-outline {
  --nwea-ds-v1-button-icon-margin-left: var(--nwea-ds-v1-button-icon-only-margin);
  --nwea-ds-v1-button-icon-margin-right: var(--nwea-ds-v1-button-icon-only-margin);
}
