/* NWEA Design System - Switch (Checkbox, Toggle, Radio Button) */

:host {
    --nwea-ds-switch-rem-conversion: calc(10 / var(--nwea-ds-v1-rem-basis, 16));
    --nwea-ds-switch-height: calc(var(--nwea-ds-switch-rem-conversion) * 2.6rem);
    --nwea-ds-switch-width: calc(var(--nwea-ds-switch-rem-conversion) * 2.6rem);
    --nwea-ds-switch-outline-border-color: var(--nwea-ds-v1-gray-700);
    --nwea-ds-switch-outline-border-width: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
}

/* Core setup of Control */
.nwea-ds-v1-switch-control {
    -webkit-appearance: none; /* Edge uses -webkit- prefix */
    -moz-appearance: none;
    appearance: none; /* Not supported, but here for when it is */
    outline: none; /* Turn off default focus ring - we implement our own */
    border: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem) solid transparent; /* This is our focus ring */
    border-radius: var(--nwea-ds-switch-focus-ring-border-radius);
    box-sizing: border-box;
    flex-shrink: 0;
    height: var(--nwea-ds-switch-height);
    width: var(--nwea-ds-switch-width);
    margin: calc(var(--nwea-ds-switch-rem-conversion) * -0.1rem) 0;
}

/* Switch outline - the box/circle itself */
.nwea-ds-v1-switch-control::before {
    content: '';
    background-color: var(--nwea-ds-switch-background-color, transparent);
    border: var(--nwea-ds-switch-outline-border-width) solid var(--nwea-ds-switch-outline-border-color);
    border-radius: var(--nwea-ds-switch-outline-border-radius, 0);
    box-sizing: border-box;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    width: var(--nwea-ds-switch-outline-width, 0);
    height: var(--nwea-ds-switch-outline-height, 0);
    margin: var(--nwea-ds-switch-outline-margin-top, 0)
            var(--nwea-ds-switch-outline-margin-side, 0)
            var(--nwea-ds-switch-outline-margin-bottom, 0)
            var(--nwea-ds-switch-outline-margin-offset, 0);
    order: -2;
}

.nwea-ds-v1-switch-control:disabled {
    --nwea-ds-switch-outline-border-color: var(--nwea-ds-v1-gray-450);
    --nwea-ds-switch-color: var(--nwea-ds-v1-gray-450);
}

.nwea-ds-v1-switch-control:focus,
.nwea-ds-v1-switch-control:not([disabled]):active {
    /* Focus ring color */
    border-color: var(--nwea-ds-switch-focus-ring, var(--nwea-ds-v1-gray-700));
    /* Box color */
    --nwea-ds-switch-outline-border-color: var(--nwea-ds-switch-focus-color);
}

/* The containing element and how it positions its children */
.nwea-ds-v1-switch-container {
    display: flex;
    align-items: flex-start;
    margin: 0;
}

/*
    '.nwea-ds-test-hover-state' is included in this declaration solely for the purposes of visual regression testing.
    The ':global()' reference instructs CSS Modules to not transform the class name.
    The ':host' reference indicates that the '.nwea-ds-test-hover-state' is being applied to the custom element itself,
       meaning that the test engine can apply it directly to the web component instead of having to dive into the shadow root.
*/
:host(.nwea-ds-test-hover-state) .nwea-ds-v1-switch-container,
.nwea-ds-v1-switch-container:hover {
    --nwea-ds-switch-color: var(--nwea-ds-switch-hover-color);
}

/* The text that 'labels' the switch */
.nwea-ds-v1-switch-label {
    color: var(--nwea-ds-v1-gray-700);
    display: flex;
    flex-grow: 1;
    margin: var(--nwea-ds-switch-label-space-top, 0) 0 0 var(--nwea-ds-switch-label-space-side, 0);
}

.nwea-ds-v1-switch-control:disabled + .nwea-ds-v1-switch-label {
    --nwea-ds-switch-color: var(--nwea-ds-v1-gray-450);
    --nwea-ds-switch-hover-color: var(--nwea-ds-v1-gray-450);
    --nwea-ds-switch-checkmark-color: white;
    color: var(--nwea-ds-v1-gray-500);
}

/* Label Positioning */
.nwea-ds-v1-switch-label-hidden {
    width: var(--nwea-ds-switch-width);
}

.nwea-ds-v1-switch-label-hidden .nwea-ds-v1-switch-label {
    display: none;
}

.nwea-ds-v1-switch-label-on-left {
    flex-direction: row-reverse;
}

.nwea-ds-v1-switch-label-on-left .nwea-ds-v1-switch-label {
    margin: var(--nwea-ds-switch-label-space-top, 0) var(--nwea-ds-switch-label-space-side, 0) 0 0;
}

.nwea-ds-v1-switch-control-is-centered, .nwea-ds-v1-switch-control-is-centered .nwea-ds-v1-switch-label {
    align-items: center;
}

/* COLORS */
.nwea-ds-v1-switch-blue {
    --nwea-ds-switch-focus-ring: var(--nwea-ds-v1-blue-700);
    --nwea-ds-switch-focus-color: var(--nwea-ds-v1-blue-500);
    --nwea-ds-switch-color: var(--nwea-ds-v1-blue-500);
    --nwea-ds-switch-hover-color: var(--nwea-ds-v1-blue-400);
}

.nwea-ds-v1-switch-purple {
    --nwea-ds-switch-focus-ring: var(--nwea-ds-v1-purple-700);
    --nwea-ds-switch-focus-color: var(--nwea-ds-v1-purple-500);
    --nwea-ds-switch-color: var(--nwea-ds-v1-purple-500);
    --nwea-ds-switch-hover-color: var(--nwea-ds-v1-purple-400);
}

.nwea-ds-v1-switch-teal {
    --nwea-ds-switch-focus-ring: var(--nwea-ds-v1-teal-900);
    --nwea-ds-switch-focus-color: var(--nwea-ds-v1-teal-700);
    --nwea-ds-switch-color: var(--nwea-ds-v1-teal-700);
    --nwea-ds-switch-hover-color: var(--nwea-ds-v1-teal-600);
}

.nwea-ds-v1-switch-orange {
    --nwea-ds-switch-focus-ring: var(--nwea-ds-v1-orange-700);
    --nwea-ds-switch-focus-color: var(--nwea-ds-v1-orange-500);
    --nwea-ds-switch-color: var(--nwea-ds-v1-orange-500);
    --nwea-ds-switch-hover-color: var(--nwea-ds-v1-orange-400);
}
