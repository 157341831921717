/* TOOLTIP classes */
.nwea-ds-v1-button-inline-left {
  --nwea-ds-v1-button-tooltip-bottom: auto;
  --nwea-ds-v1-button-tooltip-left: auto;
  --nwea-ds-v1-button-tooltip-right: var(--nwea-ds-v1-button-tooltip-offset);
  --nwea-ds-v1-button-tooltip-top: 50%;
  --nwea-ds-v1-button-tooltip-vertical-adjustment: -50%;
}

.nwea-ds-v1-button-top-left {
  --nwea-ds-v1-button-tooltip-bottom: var(--nwea-ds-v1-button-tooltip-offset);
  --nwea-ds-v1-button-tooltip-left: 0;
  --nwea-ds-v1-button-tooltip-right: initial;
  --nwea-ds-v1-button-tooltip-top: initial;
}

.nwea-ds-v1-button-top-center {
  --nwea-ds-v1-button-tooltip-bottom: var(--nwea-ds-v1-button-tooltip-offset);
  --nwea-ds-v1-button-tooltip-top: initial;
}

.nwea-ds-v1-button-top-right {
  --nwea-ds-v1-button-tooltip-bottom: var(--nwea-ds-v1-button-tooltip-offset);
  --nwea-ds-v1-button-tooltip-left: initial;
  --nwea-ds-v1-button-tooltip-right: 0;
  --nwea-ds-v1-button-tooltip-top: initial;
}

.nwea-ds-v1-button-inline-right {
  --nwea-ds-v1-button-tooltip-bottom: auto;
  --nwea-ds-v1-button-tooltip-left: var(--nwea-ds-v1-button-tooltip-offset);
  --nwea-ds-v1-button-tooltip-right: auto;
  --nwea-ds-v1-button-tooltip-top: 50%;
  --nwea-ds-v1-button-tooltip-vertical-adjustment: -50%;
}

.nwea-ds-v1-button-bottom-left {
  --nwea-ds-v1-button-tooltip-bottom: initial;
  --nwea-ds-v1-button-tooltip-left: 0;
  --nwea-ds-v1-button-tooltip-right: initial;
  --nwea-ds-v1-button-tooltip-top: var(--nwea-ds-v1-button-tooltip-offset);
}

.nwea-ds-v1-button-bottom-center {
  --nwea-ds-v1-button-tooltip-bottom: initial;
  --nwea-ds-v1-button-tooltip-top: var(--nwea-ds-v1-button-tooltip-offset);
}

.nwea-ds-v1-button-bottom-right {
  --nwea-ds-v1-button-tooltip-bottom: initial;
  --nwea-ds-v1-button-tooltip-left: initial;
  --nwea-ds-v1-button-tooltip-right: 0;
  --nwea-ds-v1-button-tooltip-top: var(--nwea-ds-v1-button-tooltip-offset);
}
