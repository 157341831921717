/*
    Trivial example of a stylesheet specifically focused on sizing properties.
 */

.nwea-ds-v1-template-small {
    --nwea-ds-template-font-size: calc(var(--nwea-ds-template-rem-conversion) * 1.6rem);
    --nwea-ds-template-margin: 0 calc(var(--nwea-ds-template-rem-conversion) * 0.4rem);
}

.nwea-ds-v1-template-medium {
    --nwea-ds-template-font-size: calc(var(--nwea-ds-template-rem-conversion) * 2.4rem);
    --nwea-ds-template-margin: 0 calc(var(--nwea-ds-template-rem-conversion) * 0.8rem);
}

.nwea-ds-v1-template-large {
    --nwea-ds-template-font-size: calc(var(--nwea-ds-template-rem-conversion) * 3.2rem);
    --nwea-ds-template-margin: 0 calc(var(--nwea-ds-template-rem-conversion) * 1.2rem);
}
