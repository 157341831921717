:host {
  --nwea-ds-single-select-rem-conversion: calc(
    10 / var(--nwea-ds-v1-rem-basis, 16)
  );
}

.nwea-ds-v1-singleSelect-experimental-select {
  width: 100%;
}

.nwea-ds-v1-singleSelect-experimental-label,
.nwea-ds-v1-singleSelect-experimental-select,
.nwea-ds-v1-singleSelect-experimental-assistiveText {
  display: block;
}
.nwea-ds-v1-singleSelect-experimental-container {
  max-width: calc(var(--nwea-ds-single-select-rem-conversion) * 60.4rem);
  min-width: calc(var(--nwea-ds-single-select-rem-conversion) * 8.8rem);
  display: inline-block;
  overflow: hidden;
}

.nwea-ds-v1-singleSelect-experimental-assistiveText,
.nwea-ds-v1-singleSelect-experimental-select:active ~ .nwea-ds-v1-singleSelect-experimental-assistiveText,
.nwea-ds-v1-singleSelect-experimental-select:hover ~ .nwea-ds-v1-singleSelect-experimental-assistiveText {
  color: var(--nwea-ds-v1-gray-600);
}

.nwea-ds-v1-singleSelect-experimental-select:disabled ~ .nwea-ds-v1-singleSelect-experimental-assistiveText {
  color: var(--nwea-ds-v1-gray-550);
}

.nwea-ds-v1-singleSelect-experimental-container.nwea-ds-v1-singleSelect-experimental-error .nwea-ds-v1-singleSelect-experimental-assistiveText {
  color: var(--nwea-ds-v1-red-600);
}

.nwea-ds-v1-singleSelect-experimental-label,
.nwea-ds-v1-singleSelect-experimental-label:hover {
  color: var(--nwea-ds-v1-gray-700);
}
.nwea-ds-v1-singleSelect-experimental-label.nwea-ds-v1-singleSelect-experimental-blue.nwea-ds-v1-singleSelect-experimental-active,
.nwea-ds-v1-singleSelect-experimental-label.nwea-ds-v1-singleSelect-experimental-blue.nwea-ds-v1-singleSelect-experimental-focused {
  color: var(--nwea-ds-v1-blue-800);
}
.nwea-ds-v1-singleSelect-experimental-label.nwea-ds-v1-singleSelect-experimental-purple.nwea-ds-v1-singleSelect-experimental-active,
.nwea-ds-v1-singleSelect-experimental-label.nwea-ds-v1-singleSelect-experimental-purple.nwea-ds-v1-singleSelect-experimental-focused {
  color: var(--nwea-ds-v1-purple-800);
}
.nwea-ds-v1-singleSelect-experimental-label.nwea-ds-v1-singleSelect-experimental-teal.nwea-ds-v1-singleSelect-experimental-active,
.nwea-ds-v1-singleSelect-experimental-label.nwea-ds-v1-singleSelect-experimental-teal.nwea-ds-v1-singleSelect-experimental-focused {
  color: var(--nwea-ds-v1-teal-800);
}

.nwea-ds-v1-singleSelect-experimental-label.nwea-ds-v1-singleSelect-experimental-disabled {
  color: var(--nwea-ds-v1-gray-550);
}

.nwea-ds-v1-singleSelect-experimental-container.nwea-ds-v1-singleSelect-experimental-error .nwea-ds-v1-singleSelect-experimental-label {
  color: var(--nwea-ds-v1-red-500);
}

.nwea-ds-v1-singleSelect-experimental-select {
  --nwea-ds-single-select-arrow-width: calc(
    var(--nwea-ds-single-select-rem-conversion) * 2.4rem
  );
  --nwea-ds-single-select-padding: calc(
    var(--nwea-ds-single-select-rem-conversion) * 1.2rem
  );
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='24px' viewBox='0 0 24 24' width='24px' fill='%23414142'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: calc(
    100% - calc(var(--nwea-ds-single-select-rem-conversion) * 1.8rem)
  );
  background-position-y: center;
  border-color: var(--nwea-ds-v1-gray-500);
  border-radius: calc(var(--nwea-ds-single-select-rem-conversion) * 0.4rem);
  border-width: calc(var(--nwea-ds-single-select-rem-conversion) * 0.1rem);
  color: var(--nwea-ds-v1-gray-700);
  height: calc(var(--nwea-ds-single-select-rem-conversion) * 4.8rem);
  margin: 0;
  outline: none;
  padding: var(--nwea-ds-single-select-padding);
  padding-right: calc(
    var(--nwea-ds-single-select-padding) +
      var(--nwea-ds-single-select-arrow-width) +
      var(--nwea-ds-single-select-padding)
  );
  text-overflow: ellipsis;
  max-width: calc(var(--nwea-ds-single-select-rem-conversion) * 60.4rem);
  min-width: calc(var(--nwea-ds-single-select-rem-conversion) * 8.8rem);
}

.nwea-ds-v1-singleSelect-experimental-select:disabled {
  background-color: var(--nwea-ds-v1-gray-150);
  border-color: var(--nwea-ds-v1-gray-450);
  color: var(--nwea-ds-v1-gray-550);
}

.nwea-ds-v1-singleSelect-experimental-select.nwea-ds-v1-singleSelect-experimental-blue:active,
.nwea-ds-v1-singleSelect-experimental-select.nwea-ds-v1-singleSelect-experimental-blue:focus {
  border-color: var(--nwea-ds-v1-blue-700);
  color: var(--nwea-ds-v1-gray-700);
}

.nwea-ds-v1-singleSelect-experimental-select.nwea-ds-v1-singleSelect-experimental-purple:active,
.nwea-ds-v1-singleSelect-experimental-select.nwea-ds-v1-singleSelect-experimental-purple:focus {
  border-color: var(--nwea-ds-v1-purple-700);
}

.nwea-ds-v1-singleSelect-experimental-select.nwea-ds-v1-singleSelect-experimental-teal:active,
.nwea-ds-v1-singleSelect-experimental-select.nwea-ds-v1-singleSelect-experimental-teal:focus {
  border-color: var(--nwea-ds-v1-teal-700);
}

.nwea-ds-v1-singleSelect-experimental-container.nwea-ds-v1-singleSelect-experimental-error .nwea-ds-v1-singleSelect-experimental-select {
  border-color: var(--nwea-ds-v1-red-500);
}
