:host {
  --nwea-ds-v1-rem-basis: 10;
}

:root {
  --standard-transition-duration: 0.25s;
  --standard-transition-timing: ease-out;
  --color-succeeded: var(--nwea-ds-v1-green-50);
  --color-succeeded-dark: var(--nwea-ds-v1-green-600);
  --color-succeeded-active: var(--nwea-ds-v1-green-500);
  --color-failed: var(--nwea-ds-v1-red-50);
  --color-failed-dark: var(--nwea-ds-v1-red-600);
  --color-failed-active: var(--nwea-ds-v1-red-500);
  --color-in-progress: var(--nwea-ds-v1-blue-50);
  --color-in-progress-dark: var(--nwea-ds-v1-blue-300);
  --color-in-progress-active: var(--nwea-ds-v1-blue-200);
  --color-na: var(--nwea-ds-v1-gray-100);
  --color-na-dark: var(--nwea-ds-v1-gray-600);
  --color-na-active: var(--nwea-ds-v1-gray-500);
  --app-footer-height: 4.2rem;
}

html {
  font-size: 62.5%;
  display: flex;
  height: 100%;
}

body,
#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  outline: solid var(--blue-200) 0.5rem;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Accessibility styling */
.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}