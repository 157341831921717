/* NWEA Design System - Checkbox */

.nwea-ds-v1-checkbox-control {
    --nwea-ds-switch-focus-ring-border-radius: calc(var(--nwea-ds-switch-rem-conversion) * 0.4rem);
    --nwea-ds-switch-outline-border-radius: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
    --nwea-ds-switch-outline-width: calc(var(--nwea-ds-switch-rem-conversion) * 1.8rem);
    --nwea-ds-switch-outline-height: calc(var(--nwea-ds-switch-rem-conversion) * 1.8rem);
    --nwea-ds-switch-outline-margin-top: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
    --nwea-ds-switch-outline-margin-offset: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
    --nwea-ds-switch-icon-content: none;
    --nwea-ds-switch-icon-margin-offset: calc(var(--nwea-ds-switch-rem-conversion) * -2.1rem);
    --nwea-ds-switch-icon-margin-side: calc(var(--nwea-ds-switch-rem-conversion) * 0.7rem);
}

.nwea-ds-v1-checkbox-control:disabled ~ .nwea-ds-v1-checkbox-icon {
    --nwea-ds-switch-color: var(--nwea-ds-v1-gray-450);
}

.nwea-ds-v1-checkbox-label {
    --nwea-ds-switch-label-space-side: calc(var(--nwea-ds-switch-rem-conversion) * 0.6rem);
}

.nwea-ds-v1-checkbox-icon {
    fill: var(--nwea-ds-switch-color);
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(var(--nwea-ds-switch-rem-conversion) * 2.4rem);
    margin: 0 calc(var(--nwea-ds-switch-rem-conversion) * -2.5rem) 0 0;
    width: calc(var(--nwea-ds-switch-rem-conversion) * 2.4rem);
    opacity: 0;
    order: -1;
}

.nwea-ds-v1-checkbox-indeterminate {
    display: none;
}

.nwea-ds-v1-switch-label-on-left .nwea-ds-v1-checkbox-icon {
    margin: 0 0 0 calc(var(--nwea-ds-switch-rem-conversion) * -2.5rem);
}

.nwea-ds-v1-switch-switch-is-centered .nwea-ds-v1-checkbox-label {
    --nwea-ds-switch-outline-margin-top: 0;
}

.nwea-ds-v1-checkbox-control:checked::before,
.nwea-ds-v1-checkbox-control:indeterminate::before,
.nwea-ds-v1-checkbox-control[indeterminate]::before {
    animation: nwea-ds-v1-checkbox-show-checkmark 0.3s;
    border-color: transparent var(--nwea-ds-switch-color) transparent transparent;
    border-width: 0 0 0 calc(var(--nwea-ds-switch-outline-width));
}

.nwea-ds-v1-checkbox-control:checked ~ .nwea-ds-v1-checkbox-icon,
.nwea-ds-v1-checkbox-control:indeterminate ~ .nwea-ds-v1-checkbox-icon,
.nwea-ds-v1-checkbox-control[indeterminate] ~ .nwea-ds-v1-checkbox-icon {
    opacity: 1;
    transition: opacity 0.15s step-end;
}

.nwea-ds-v1-checkbox-control:indeterminate ~ .nwea-ds-v1-checkbox-checkmark,
.nwea-ds-v1-checkbox-control[indeterminate] ~ .nwea-ds-v1-checkbox-checkmark {
    display: none;
}

.nwea-ds-v1-checkbox-control:indeterminate ~ .nwea-ds-v1-checkbox-indeterminate,
.nwea-ds-v1-checkbox-control[indeterminate] ~ .nwea-ds-v1-checkbox-indeterminate {
    display: initial;
}

@keyframes nwea-ds-v1-checkbox-show-checkmark {
    0% {
        background-color: transparent;
        border-color: var(--nwea-ds-switch-outline-border-color);
        border-width: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
    }

    50% {
        background-color: var(--nwea-ds-switch-color);
        border-color: var(--nwea-ds-switch-color);
        border-width: calc(var(--nwea-ds-switch-rem-conversion) * 0.2rem);
    }

    51% {
        background-color: var(--nwea-ds-switch-color);
        border-color: transparent var(--nwea-ds-switch-color) transparent transparent;
        border-width: 0 var(--nwea-ds-switch-outline-width) 0 0;
    }

    100% {
        /* Animating from full Right border to full Left border causes a "jiggle" at the end */
        /* Hence, animating to 1px shy of full width */
        border-width: 0 calc(var(--nwea-ds-switch-rem-conversion) * 0.1rem) 0 calc(var(--nwea-ds-switch-outline-width) - calc(var(--nwea-ds-switch-rem-conversion) * 0.1rem));
    }
}