/* NWEA Design System - Colors */

:root, :host {
    /* Grayscale */
    --nwea-ds-v1-gray-50: #fafafa;
    --nwea-ds-v1-gray-100: #eeeef0;
    --nwea-ds-v1-gray-150: #dedee0;
    --nwea-ds-v1-gray-200: #cdcdd0;
    --nwea-ds-v1-gray-300: #b1b1b5;
    --nwea-ds-v1-gray-400: #98989d;
    --nwea-ds-v1-gray-450: #8f8f94;
    --nwea-ds-v1-gray-500: #76767a;
    --nwea-ds-v1-gray-550: #626264;
    --nwea-ds-v1-gray-600: #575758;
    --nwea-ds-v1-gray-700: #414142;
    --nwea-ds-v1-gray-800: #2c2c2f;
    --nwea-ds-v1-gray-900: #161616;

    /* Yellow */
    --nwea-ds-v1-yellow-50: #fffbf0;
    --nwea-ds-v1-yellow-100: #fff4ae;
    --nwea-ds-v1-yellow-200: #ffea86;
    --nwea-ds-v1-yellow-300: #fee05e;
    --nwea-ds-v1-yellow-400: #fed535;
    --nwea-ds-v1-yellow-500: #fec10d;
    --nwea-ds-v1-yellow-600: #ffaf00;
    --nwea-ds-v1-yellow-700: #fe9b09;
    --nwea-ds-v1-yellow-800: #ff8c00;
    --nwea-ds-v1-yellow-900: #ff7800;

    /* Blue */
    --nwea-ds-v1-blue-50: #f2f9ff;
    --nwea-ds-v1-blue-100: #b3e4ff;
    --nwea-ds-v1-blue-200: #7ac7ff;
    --nwea-ds-v1-blue-300: #46a0ee;
    --nwea-ds-v1-blue-400: #1681d9;
    --nwea-ds-v1-blue-500: #0066bb;
    --nwea-ds-v1-blue-600: #0055a0;
    --nwea-ds-v1-blue-700: #004b88;
    --nwea-ds-v1-blue-800: #003f70;
    --nwea-ds-v1-blue-900: #003159;

    /* Teal */
    --nwea-ds-v1-teal-50: #eefdff;
    --nwea-ds-v1-teal-100: #c9f2f7;
    --nwea-ds-v1-teal-200: #a3e8ef;
    --nwea-ds-v1-teal-300: #7edde8;
    --nwea-ds-v1-teal-400: #58d3e0;
    --nwea-ds-v1-teal-500: #33c8d8;
    --nwea-ds-v1-teal-600: #25b5c5;
    --nwea-ds-v1-teal-700: #0f828f;
    --nwea-ds-v1-teal-800: #077582;
    --nwea-ds-v1-teal-900: #006774;

    /* Green */
    --nwea-ds-v1-green-50: #ecfcee;
    --nwea-ds-v1-green-100: #cef0d2;
    --nwea-ds-v1-green-200: #aae1b0;
    --nwea-ds-v1-green-300: #85d28d;
    --nwea-ds-v1-green-400: #61c36b;
    --nwea-ds-v1-green-500: #3cb449;
    --nwea-ds-v1-green-600: #299f36;
    --nwea-ds-v1-green-700: #1c8828;
    --nwea-ds-v1-green-800: #0f721a;
    --nwea-ds-v1-green-900: #005c0a;

    /* Lime */
    --nwea-ds-v1-lime-50: #fdffe7;
    --nwea-ds-v1-lime-100: #f2f7aa;
    --nwea-ds-v1-lime-200: #ebf380;
    --nwea-ds-v1-lime-300: #e4ee5f;
    --nwea-ds-v1-lime-400: #deea3f;
    --nwea-ds-v1-lime-500: #d7e600;
    --nwea-ds-v1-lime-600: #cedc07;
    --nwea-ds-v1-lime-700: #c0d100;
    --nwea-ds-v1-lime-800: #afc400;
    --nwea-ds-v1-lime-900: #99b000;

    /* Purple */
    --nwea-ds-v1-purple-50: #f4f3ff;
    --nwea-ds-v1-purple-100: #d3caff;
    --nwea-ds-v1-purple-200: #b7a1ff;
    --nwea-ds-v1-purple-300: #9d76ff;
    --nwea-ds-v1-purple-400: #844ffa;
    --nwea-ds-v1-purple-500: #6a30df;
    --nwea-ds-v1-purple-600: #5c25c5;
    --nwea-ds-v1-purple-700: #4e1aab;
    --nwea-ds-v1-purple-800: #3f0f91;
    --nwea-ds-v1-purple-900: #310477;

    /* Pink */
    --nwea-ds-v1-pink-50: #fff3f9;
    --nwea-ds-v1-pink-100: #ffc3e2;
    --nwea-ds-v1-pink-200: #f483be;
    --nwea-ds-v1-pink-300: #e159a0;
    --nwea-ds-v1-pink-400: #d23787;
    --nwea-ds-v1-pink-500: #c22e7b;
    --nwea-ds-v1-pink-600: #b1276f;
    --nwea-ds-v1-pink-700: #9f1f62;
    --nwea-ds-v1-pink-800: #88104f;
    --nwea-ds-v1-pink-900: #70003b;

    /* Red */
    --nwea-ds-v1-red-50: #fff0f0;
    --nwea-ds-v1-red-100: #ffbdbd;
    --nwea-ds-v1-red-200: #ff877e;
    --nwea-ds-v1-red-300: #ff6259;
    --nwea-ds-v1-red-400: #fa3732;
    --nwea-ds-v1-red-500: #e91b19;
    --nwea-ds-v1-red-600: #d70b03;
    --nwea-ds-v1-red-700: #bf0a00;
    --nwea-ds-v1-red-800: #a40000;
    --nwea-ds-v1-red-900: #8f0000;

    /* Orange */
    --nwea-ds-v1-orange-50: #fff5ee;
    --nwea-ds-v1-orange-100: #ffcdad;
    --nwea-ds-v1-orange-200: #ff9d5f;
    --nwea-ds-v1-orange-300: #ff8537;
    --nwea-ds-v1-orange-400: #ff6602;
    --nwea-ds-v1-orange-500: #f85105;
    --nwea-ds-v1-orange-600: #ec470c;
    --nwea-ds-v1-orange-700: #d93d00;
    --nwea-ds-v1-orange-800: #c53000;
    --nwea-ds-v1-orange-900: #b12800;
}
